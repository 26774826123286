<template>
  <base-section
    id="carousel-with-word"
    class="my-0 py-0"
  >
    <v-container>
      <v-slide-y-transition>
        <v-carousel
          continuous
          hide-delimiters
          show-arrows-on-hover
          :height="$vuetify.breakpoint.mdAndUp ? 700 : 300"
          cycle
          interval="3000"
          progress
          progress-color="primary"
        >
          <v-carousel-item
            v-for="n in 13"
            :key="n"
            :src="require(`@/assets/gallery-${n}.jpg`)"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-slide-y-transition>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCarouselWithWord',
  }
</script>
